import React, { Fragment, useContext } from "react";
import {
  StyledReviews,
  StyledReview,
  StyledReviewContent,
  StyledReviewStars,
  StyledReviewStar,
  StyledReviewAuthor,
  StyledReviewText,
} from "./style.jsx";
import { StyledGridRow } from "../../commons/Grid";
import Scroller from "../Scroller/Scroller";
import Button from "../Button/Button";
import { DataContext } from "../../contexts/DataContext";
import SectionHeader from "../SectionHeader/SectionHeader.jsx";

const plan_color = ["ligthyellow", "pink", "greenlight"]
const getStars = (stars) => {
  const array = [];

  for (let i = 0; i < 5; i++) {
    array.push(i);
  }
  return array.map((x, index) => {
    return (
      <StyledReviewStar key={index} isActive={index <= Number(stars) - 1}>
        ★
      </StyledReviewStar>
    );
  });
};

export default ({ highlighted, items, title }) => {
  const { labels } = useContext(DataContext);

  return (
    <StyledGridRow isFullMobile>
      {title && <SectionHeader title={title} textAlign="left" padding={"0 2rem"}/>}
      <Scroller>
        <StyledReviews>
          {items.map((item, index) => (
            <StyledReview color={plan_color[index % 3]} key={index} highlighted={highlighted}>
              <StyledReviewContent>
                {item.stars && (
                  <StyledReviewStars>{getStars(item.stars)}</StyledReviewStars>
                )}
                <StyledReviewAuthor>{item.author}</StyledReviewAuthor>
                <StyledReviewText>{item.review}</StyledReviewText>
                {item.url && (<Button as="div" noPadding theme="yellow" to={item.url}>{labels.SUBMITNOW}</Button>)}
              </StyledReviewContent>
            </StyledReview>
          ))}
        </StyledReviews>
      </Scroller>
    </StyledGridRow>
  );
};
