import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Shadow,
} from "../../commons/Theme";

export const StyledReviews = styled.div`
  min-width: calc(((80vw + ${Rem(20)}) * 3) + ${Rem(20)});
  display: flex;
  align-items: stretch;
  padding-bottom: ${Rem(24)};
  overflow: hidden;

  @media (${BP.ipad}) {
    min-width: 0;
    align-items: stretch;
    justify-content: space-between;
  }
`;

export const StyledReview = styled.div`
  min-width: 80vw;
  max-width: 80vw;
  margin-left: ${Rem(20)};

  &:last-child {
    &:after {
      position: relative;
      content: '';
      display: block;
      width: ${Rem(20)};
      height: 100%;
      left: 100%;
      background-color: ${Colors.transparent};

      @media (${BP.ipad}) {
        display: none;
      }
    }
  }
  
  ${(props) =>
    props.highlighted
    && css`

      ${StyledReviewContent} {
        background-color: ${props.color ? Colors[props.color] : Colors.white};
      }
    `}

  @media (${BP.ipad}) {
    min-width: calc((100% / 3) - ${Rem(20)});
    max-width: calc((100% / 3) - ${Rem(20)});
    margin: 0;
  }
`;

export const StyledReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${Colors.grayLight};
  padding: ${Rem(40)} ${Rem(24)};

  @media (${BP.ipad}) {
    padding: ${Rem(44)} ${Rem(52)};
  }
`;

export const StyledReviewStars = styled.div`
  margin-bottom: ${Rem(20)};
`;

export const StyledReviewStar = styled.span`
  font-size: ${Rem(20)};
  padding-right: ${Rem(4)};
  color: ${Colors.gray};

  @media (${BP.ipad}) {
    font-size: ${Rem(28)};
  }

  ${(props) =>
    props.isActive
    && css`
      color: ${Colors.yellow};
    `}
`;

export const StyledReviewAuthor = styled.span`
  font-size: ${Rem(22)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(25)};
    margin-bottom: ${Rem(32)};
  }
`;

export const StyledReviewText = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(27)};
`;
